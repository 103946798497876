<template>
  <div class="container">
    <empresa-combo :no-style="false" :selecionar="changeEmpresa" />
    <hr v-if="user.tipo == 'Administrador'" />
    <div class="row">
      <div class="col-12 col-sm-4" :class="{ 'col-sm-4 mt-2': user.nivel > 2 }">
        <h4 v-if="user.nivel > 2">Data</h4>
        <v-date-picker
          v-model="dataSel"
          mode="date"
          :rows="1"
          spellcheck="false"
          is-expanded
          is-required
          v-show="!loading"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="form-control text-center"
              :disabled="loading"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <div v-if="loading" class="mt-2">
          <b-spinner /> carregando {{ loadingText }}...
        </div>

        <div class="row" v-if="!loading && user.nivel <= 2">
          <div class="col-12 pt-3">
            <b-tabs content-class="">
              <b-tab
                title="Caixas"
                :active="selectedCaixa && selectedCaixa.tipo == 'caixa'"
              >
                <template #title>
                  <i class="fa fa-cash-register" /> Caixas
                </template>
                <caixa-card
                  :obj="caixa"
                  v-for="caixa of caixas"
                  :key="`cx_${caixa.cod_caixa}_${dataSel}`"
                  :is-moto="false"
                  @click.native="selected(caixa)"
                  :class="{ cxSelected: caixa.opened }"
                />
              </b-tab>
              <b-tab
                title="Motos"
                :active="selectedCaixa && selectedCaixa.tipo == 'moto'"
              >
                <template #title>
                  <i class="fa fa-motorcycle" /> Moto
                </template>
                <caixa-card
                  :obj="moto"
                  v-for="moto of motos"
                  :key="moto.cod_moto"
                  :is-moto="true"
                  @click.native="selected(moto)"
                  :class="{ cxSelected: moto.opened }"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 p-0 pr-1" v-if="!loading && user.nivel <= 2">
        <b-tabs content-class="" v-model="selectedCaixa.aba">
          <b-tab title="Resumo">
            <template #title> <b-icon-calculator /> Resumo </template>

            <caixa-resumo
              :caixa-prop="objSelecionado"
              v-if="objSelecionado"
              :data-sel="dataSelString"
              :is-moto="
                Object.keys(objSelecionado).find((x) => x == 'cod_moto') != null
              "
              :empresaSelecionada="empresa"
              @carregar="carregar"
            />
          </b-tab>
          <b-tab title="Fechamento / Retiradas">
            <template #title> <b-icon-eject /> Saidas / Fechamentos </template>
            <h3>
              <i class="fa fa-cash-register mr-1" />
              {{
                objSelecionado && objSelecionado.nome
                  ? objSelecionado.nome
                  : objSelecionado && objSelecionado.moto
                  ? objSelecionado.moto
                  : ""
              }}
              -
              <small
                ><b-icon-calendar class="mr-1" />
                {{ dataSelString | moment("DD/MM/YYYY") }}</small
              >
            </h3>

            <caixa-user
              :data="dataSel"
              :caixa="objSelecionado"
              :empresa-prop="empresa"
              @updateCaixas="carregar"
            />
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-12 col-sm-12 p-0 pr-1" v-if="user.nivel > 2">
        <div v-if="loading"><b-spinner /> carregando {{ loadingText }}...</div>
        <hr />
        <caixa-user
          :data="dataSel"
          :empresa-prop="empresa"
          @updateCaixas="carregar"
          :caixa="objSelecionado"
        />
      </div>
    </div>

    <b-modal
      id="modal-saidas"
      hide-footer
      size="xl"
      @hidden="carregar"
      title="Saídas / Retiradas"
    >
      <saidas
        :caixa-tipo="caixa_modal"
        :empresa-ativa="empresa"
        @updateCaixas="carregar"
      />
    </b-modal>
  </div>
</template>

<script>
// import BarraCrud from "../../components/common/BarraCrud.vue";
import moment from "moment";
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
import Saidas from "../../components/Caixas/Saidas.vue";
import CaixaLib from "../../libs/Caixas";
import CaixaUser from "./CaixaUser.vue";

import CaixaCard from "./CaixaCard.vue";
import CaixaResumo from "./CaixaResumo.vue";
const dataDef = moment().add(parseInt(moment().format("HH")) < 8 ? -1 : 0, "d");
// console.log(
//   "data===================",
//   dataDef.format("DD/MM HH:mm"),
//   "h",
//   parseInt(moment().format("HH")),
//   "a",
//   parseInt(moment().format("HH")) < 8 ? -1 : 0
// );
export default {
  components: {
    // BarraCrud,
    EmpresaCombo,
    Saidas,
    CaixaUser,
    CaixaCard,
    CaixaResumo,
  },
  data() {
    return {
      objSelecionado: null,
      empresa: this.$store.state.empresa_ativa,
      dataSel: dataDef.toDate(),
      dataSelString: dataDef.format("YYYY-MM-DD"),
      user: this.$store.state.auth.user,
      caixas: Object.assign([], this.$store.state.caixas),
      motos: [],
      showAll: true,
      caixa_modal: { tipo: "", cod: "" },
      loading: false,
      loadingText: "caixas",
      loadingVl: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      },
      informados: [],
      imagem: "",
      selectedCaixa: {
        cod_caixa: 1,
        cod_moto: 0,
        data: "",
        tipo: "caixa",
        aba: 0,
      },
    };
  },
  mounted() {
    // this.carregar();
    if (this.user.tipo == "Caixa") {
      this.selected(
        this.caixas.find((x) => x.cod_caixa == this.user.cod_caixa)
      );
    }
  },
  watch: {
    dataSel: function () {
      if (!this.dataSel) {
        this.dataSel = new Date();
        this.$forceUpdate();
      }
      this.dataSelString = moment(this.dataSel).format("YYYY-MM-DD");
      // console.log(this.dataSel, moment(this.dataSel).format("DD/MM"));
      this.carregar();
    },
    showAll: function () {
      // this.caixas.map(cx => {
      //   cx.opened = this.showAll;
      // });
      // this.motos.map(cx => {
      //   cx.opened = this.showAll;
      // });
      this.$forceUpdate();
    },
  },
  methods: {
    data() {
      return moment(this.dataSel).format("YYYY-MM-DD");
    },
    selected(caixa) {
      // console.log(19999999);
      this.caixas.map((it) => {
        it.opened = false;
      });
      this.motos.map((it) => {
        it.opened = false;
      });
      caixa.opened = true;
      this.objSelecionado = caixa;

      this.selectedCaixa.data = caixa.data;
      this.selectedCaixa.cod_caixa = caixa.cod_caixa;
      this.selectedCaixa.cod_moto = caixa.cod_moto;
      this.selectedCaixa.tipo = caixa.cod_moto ? "moto" : "caixa";

      this.empresa = [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ].find((x) => x.cod_empresa == caixa.cod_empresa);
      // this.dataSel = moment(caixa.data).format("YYYY-MM-DD");
      this.$forceUpdate();
      this.$nextTick();
      // console.log("selectedCaixa: ", caixa, this.selectedCaixa);

      // this.caixas.find(x=>x.cod_caixa = caixa.cod_caixa).opened = true;
      // console.log("cxaaaaa", this.objSelecionado);
    },

    changeEmpresa(empresa) {
      this.empresa = empresa;
      this.carregar();
    },
    abrirSaidas(tipo, cod, desc) {
      this.caixa_modal = { tipo, cod, desc };
      this.$bvModal.show("modal-saidas");
    },

    async carregar() {
      if (this.loading) return;

      // console.log("caixas carregar");
      //this.objSelecionado = null;
      this.loading = true;
      this.loadingText = "caixas";
      await this.$nextTick();
      this.caixas = [
        {
          cod_caixa: -1,
          nome: "TODOS",
          cod_empresa: this.empresa.cod_empresa,
          data: this.dataSel,
          resumo: [],
        },
        ...(await CaixaLib.getControle(
          this.empresa.cod_empresa,
          moment(this.dataSel).format("YYYY-MM-DD")
        )),
      ];
      this.loadingText = "calculo de caixas";
      await this.$nextTick();

      // console.log("caixas raw", this.caixas);

      this.caixas.find((x) => x.nome && x.nome == "TODOS").resumo = this.caixas
        .filter(
          (x) => x.nome && x.nome != "TODOS" && (!x.salao || x.salao !== 1)
        )
        .reduce((ret, vla) => {
          for (let vl of vla.resumo) {
            let obj = ret.find((s) => s.tipo_entrega == vl.tipo_entrega);
            let tem = true;
            if (!obj) {
              obj = {};
              tem = false;
            }
            for (let k of Object.keys(vl)) {
              if (
                Object.keys(obj).find((st) => st == k) &&
                typeof vl[k] == "number"
              ) {
                obj[k] += vl[k];
              } else {
                obj[k] = vl[k];
              }
            }
            if (!tem) {
              ret.push(obj);
            }
          }
          return ret;
        }, []);

      // console.log("cx", this.caixas);
      this.caixas.map((cx) => {
        cx.opened = false;
      });
      this.loadingText = "motos";
      await this.$nextTick();
      // console.log("cxxxxxxxxxxxxxsss", this.empresa.cod_empresa,)
      let result_motos = await CaixaLib.getMotosControle(
        this.empresa.cod_empresa,
        moment(this.dataSel).format("YYYY-MM-DD")
      );
      this.motos = [
        {
          cod_moto: -1,
          moto: "TODOS",
          cod_empresa: this.empresa.cod_empresa,
          data: this.dataSel,
          valor_entrada: 0,
        },
        ...result_motos,
      ];
      this.motos.map((cx) => {
        cx.opened = false;
      });
      console.log("motos", this.motos, this.empresa.cod_empresa);
      this.loadingText = "calculo de motos";
      await this.$nextTick();
      let tot = this.motos.find((x) => x.moto == "TODOS");
      if (tot) {
        tot.problema = {
          vl_dim: this.motos
            .filter((x) => x.moto != "TODOS")
            .reduce((r, v) => {
              return r + v.problema.vl_dim;
            }, 0),
          vl_car: this.motos
            .filter((x) => x.moto != "TODOS")
            .reduce((r, v) => {
              return r + v.problema.vl_car;
            }, 0),
          qt_prob: this.motos
            .filter((x) => x.moto != "TODOS")
            .reduce((r, v) => {
              return r + v.problema.qt_prob;
            }, 0),
        };
        for (let vl of this.motos.filter((x) => x.moto != "TODOS")) {
          if (!tot.porValor) {
            tot.porValor = [];
          }
          if (vl.porValor) {
            for (let p of vl.porValor) {
              let pv = tot.porValor.find((c) => c.vl_entrega == p.vl_entrega);
              if (pv) {
                pv.qtd += p.qtd;
              } else {
                tot.porValor.push({
                  vl_entrega: p.vl_entrega,
                  qtd: p.qtd,
                });
              }
            }
          }
          for (let k of Object.keys(vl)) {
            if (k != "cod_moto" && !k.includes("cod_")) {
              if (
                Object.keys(tot).find((st) => st == k) &&
                typeof vl[k] == "number"
              ) {
                tot[k] += vl[k];
              } else if (
                !Object.keys(tot).find((st) => st == k) &&
                typeof vl[k] == "number"
              ) {
                tot[k] = vl[k];
              } else if (k == "valor_entrada") {
                // console.log("vl_entrada", vl["valor_entrada"]);
                tot["valor_entrada"] += +vl["valor_entrada"];
              }
            }
          }
          // console.log("total moto", tot);
        }
      }
      this.loadingText = "informados";
      console.log("chama1", this.empresa.cod_empresa);
      await this.$nextTick();
      this.informados = await CaixaLib.getFechamentos(
        this.empresa.cod_empresa,
        moment(this.dataSel).format("YYYY-MM-DD"),
        null,
        null,
        null
      );
      // console.log("informados all", this.informados);

      this.caixas.map((cx) => {
        cx.informados = this.informados.filter(
          (x) => x.cod_caixa == cx.cod_caixa
        );
      });
      this.motos.map((mt) => {
        mt.informados = this.informados.filter(
          (x) => x.cod_moto && x.cod_moto == mt.cod_moto
        );
      });

      // console.log("caixas", this.caixas);
      // console.log("motso", this.motos);

      this.loadingText = "concluindo";
      await this.$nextTick();
      this.$forceUpdate();
      await this.$nextTick();

      // console.log("finfind selected", this.selectedCaixa);
      let HasLasSelected =
        this.selectedCaixa && this.selectedCaixa.cod_moto
          ? this.motos.find((x) => x.cod_moto == this.selectedCaixa.cod_moto)
          : this.caixas.find(
              (x) => x.cod_caixa == this.selectedCaixa.cod_caixa
            );
      // console.log("has selected", HasLasSelected);
      if (HasLasSelected) {
        this.selected(HasLasSelected);
      }

      // if (this.objSelecionado) {
      //   console.log("aqui", this.objSeelcionado, this.caixas);
      //   // this.selected(this.caixas.find(x=>x.id == this.objSeelcionado.id));
      // } else {
      //   this.selected(this.caixas[0]);
      // }
      //if (!this.objSelecionado) {
      //}
      this.loading = false;
    },

    mostraCaixa(caixa) {
      return (
        this.user.tipo == "Administrador" ||
        this.user.tipo == "Gerente Caixa" ||
        this.user.tipo == "Gerente" ||
        this.$store.state.caixa_ativo.cod_caixa == caixa.cod_caixa
      );
    },
    openDetail(caixa) {
      // console.log("caixa", caixa);
      caixa.opened = !caixa.opened;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/caixas.scss"
</style>
